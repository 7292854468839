import {
  getStoryblokApi,
  StoryblokComponent,
  useStoryblokState,
} from '@storyblok/react'
import { InferGetServerSidePropsType } from 'next'
import Script from 'next/script'

import { Layout } from '#components/Layout'
import { withAuthStatus } from '#lib/authentication/pageHelpers'
import { environment } from '#lib/environment.mjs'
import { getFlags } from '#lib/featureFlag'
import { PageStoryblok } from '#types/storyblok-component-types'

const HomePage = ({
  story: initialStory,
}: InferGetServerSidePropsType<
  typeof getServerSideProps
>): JSX.Element | null => {
  const story = useStoryblokState<PageStoryblok>(initialStory, {
    preventClicks: true,
  })

  return (
    <>
      <Script id="organization-metadata" type="application/ld+json">
        {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "@id": "https://www.qogita.com/#organization",
              "name": "Qogita",
              "description": "Qogita is a B2B wholesale marketplace specializing in branded health and beauty products. Headquartered in the UK, Qogita operates across the entire Europe. The platform allows retailers to purchase authentic products directly from a vast network of brands and trusted wholesalers across Europe.",
              "logo": "https://static.prod.qogita.com/logo/qogita.png",
              "url": "https://www.qogita.com/",
              "contactPoint": [
                {
                  "@type": "ContactPoint",
                  "contactType": "Customer Service",
                  "email": "support@qogita.com",
                  "areaServed": ["EU", "UK"],
                  "availableLanguage": ["English"]
                },
                {
                  "@type": "ContactPoint",
                  "contactType": "Sales",
                  "email": "sales@qogita.com",
                  "areaServed": ["EU", "UK"],
                  "availableLanguage": ["English"]
                }
              ],
              "address": [
                {
                  "@type": "PostalAddress",
                  "streetAddress": "1 Poultry",
                  "addressLocality": "London",
                  "postalCode": "EC2R 8EJ",
                  "addressCountry": "UK"
                },
                {
                  "@type": "PostalAddress",
                  "streetAddress": "Weesperstraat 61",
                  "addressLocality": "Amsterdam",
                  "postalCode": "1018 VN",
                  "addressCountry": "NL"
                }
              ],
              "sameAs": [
                "https://www.instagram.com/qogita_wholesale/",
                "https://uk.linkedin.com/company/qogita",
                "https://x.com/qogita",
                "https://www.youtube.com/@QogitaWholesale",
                "https://www.facebook.com/qogita/"
              ]
            }
          `}
      </Script>
      <Layout
        pageBlok={story?.content || null}
        padded={false}
        title={
          story?.content.metadata?.title ||
          "Europe's wholesale marketplace for health & beauty retailers | Qogita"
        }
        description={
          story?.content.metadata?.description ||
          'Access exclusive brands, low prices and hot deals from a combined catalog of 400+ suppliers. 10k+ brands available with guaranteed authenticity approved for EU & UK retail. Make procurement simple with our one-stop-shop solution.'
        }
      >
        {story ? (
          <StoryblokComponent blok={story.content} />
        ) : (
          <div>story is null</div>
        )}
      </Layout>
    </>
  )
}

export const getServerSideProps = withAuthStatus(async ({ query }) => {
  if (environment.IS_E2E) {
    return { props: {} }
  }

  const featureFlagState = await getFlags()

  const isInStoryblokIframe = Boolean(query._storyblok)

  const slug = 'home'
  const storyblokApi = getStoryblokApi()
  const { data } = await storyblokApi.get(`cdn/stories/${slug}`, {
    version: isInStoryblokIframe ? 'draft' : 'published',
    resolve_links: 'url',
    resolve_relations: ['testimonialSimplePanel.testimonial', 'page.footer'],
  })

  return {
    props: {
      story: data.story,
      featureFlagState,
    },
  }
})

export default HomePage
